@font-face {
  font-family: "poppins";
  src: url("./assets/fonts/Poppins/Poppins-Bold.ttf");
}

* {
  margin: 0;
  padding: 0;
}

.backgroundimage {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6) 30%, rgba(0, 0, 0, 0.9)),
    url(./assets/henoralogo.png);
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  filter: blur(6px);
}

.backgroundcolor {
  height: 100vh;
  width: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  filter: blur(10px);
  background: black;
  transform: scale(1.1);
}

.hero {
  height: 100vh;
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}

.header {
  position: absolute;
  top: 0;
  left: 0;
}

/* just to center the text and the button inside his parent */
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text1 {
  font-family: poppins;
  color: white;
  position: fixed;
  left: 140px;
  top: 60px;
  font-size: 23px;
}

.text2 {
  font-family: poppins;
  color: white;
  padding: 0;
  font-size: 115px;
}

.text3 {
  font-family: poppins;
  color: white;
  padding: 0;
  padding-bottom: 11px;
  font-size: 10px;
}

.tos2 {
  position: fixed;
  left: 140px;
  bottom: 60px;
}

.tos {
  position: fixed;
  right: 140px;
  bottom: 60px;
  cursor: default;
}

.socials {
  position: fixed;
  right: 140px;
  top: 60px;
  cursor: default;
}

.socialIcon {
  margin-left: 20px;
  cursor: pointer;
}
